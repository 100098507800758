<template>
  <div class="pp">
    <div class="review">
      <div class="title">—— 您对本次服务满意吗 ——</div>
      <div class="icon">
        <div v-for="(item, index) in list" :key="index" @click="chooese(index)">
          <svg-icon
            :icon-class="cur == index ? item.iconAct : item.icon"
          ></svg-icon>
          <span :class="cur != -1 ? 's_act' : ''">{{ item.label }}</span>
        </div>
      </div>

      <div class="textarea">
        <svg-icon
          v-if="text.length == 0"
          class="edit"
          icon-class="chat_review_edit"
        ></svg-icon>
        <textarea
          maxlength="200"
          placeholder="      点击输入想说的话"
          v-model="text"
        >
        </textarea>
      </div>

      <div class="btn" :class="cur != -1 ? 'btn_act' : ''" @click="sub">
        提交
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@get/http";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      cur: -1,
      list: [
        {
          icon: "r1-1",
          iconAct: "r1",
          label: "很不满",
        },
        {
          icon: "r2-1",
          iconAct: "r2",
          label: "不满",
        },
        {
          icon: "r3-1",
          iconAct: "r3",
          label: "一般",
        },
        {
          icon: "r4-1",
          iconAct: "r4",
          label: "满意",
        },
        {
          icon: "r5-1",
          iconAct: "r5",
          label: "很满意",
        },
      ],
      text: "",
    };
  },
  methods: {
    chooese(index) {
      this.cur = index;
    },
    async sub() {
      if (this.cur == -1) {
        Toast("请评价~");
        return;
      }
      if (!this.$route.query?.seller_id) {
        Toast("链接好像出了点问题~");
        return;
      }
      let goods = await post("/v3.MemberSellerEvaluationToC/write_in_seller", {
        data: {
          seller_id: this.$route.query.seller_id,
          score: this.cur + 1,
          remark: this.text,
        },
      });
      Dialog.alert({
        message: goods.message,
      }).then(() => {
        // on close
        window.location.href = "https://h5.shop.19jiang.cn/home/index?tab=shop";
      });
    },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  background: #fff;
}
</style>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.pp {
  width: 100%;
  height: 100%;
  background: #fff;
}
.review {
  width: rem(686);
  display: block;
  margin: 0 auto;
  margin: auto;
}
.title {
  font-weight: 400;
  font-size: rem(30);
  line-height: rem(42);
  color: #666666;
  text-align: center;
  margin-bottom: rem(38);
  padding-top: rem(96);
}
.icon {
  display: flex;
  > div {
    flex: 1;
    display: flex;
    align-items: center;

    justify-content: center;
    flex-direction: column;
    svg {
      width: rem(56);
      height: rem(56);
      margin-bottom: rem(16);
    }
    .s_act {
      color: #ff4848 !important;
    }
    span {
      font-weight: 400;
      font-size: rem(28);
      line-height: rem(39);

      color: #222222;
    }
  }
}

.btn {
  margin-top: rem(96);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: rem(38);
  line-height: rem(53);
  color: #ffffff;
  width: rem(686);
  height: rem(88);
  background: linear-gradient(
    180deg,
    rgba(255, 72, 72, 0.4) 0%,
    rgba(234, 62, 62, 0.4) 100%
  );
  border-radius: rem(83);
}

.btn_act {
  background: linear-gradient(180deg, #ff4848 0%, #ea3e3e 100%);
}

.textarea {
  margin-top: rem(48);
  position: relative;
  .edit {
    position: absolute;
    width: rem(44);
    height: rem(44);
    display: block;
    top: rem(32-10);
    left: rem(24);
    z-index: 9999;
  }
}
textarea {
  width: rem(686);
  height: rem(300);
  background: #f9f9f8;
  border-radius: rem(24);
  box-sizing: border-box;
  padding: rem(24) rem(32);
  color: #222222;
  font-size: rem(26);
  font-weight: 400;

  line-height: rem(36);
}

textarea ::-webkit-input-placeholder {
  color: #999999;
}
textarea ::-moz-placeholder {
  color: #999999;
}
textarea ::-ms-input-placeholder {
  color: #999999;
}
</style>
